<template lang='pug'>
#app(@click="handleRootClick")
  router-view
  #bgm
    mp3-player(ref="Mp3Player")
  el-drawer(custom-class="android-hint" :title="drawer.title" :visible.sync="isPromptShow" direction="btt" :modal="false" :before-close="handleDrawerClose")
    .flex.flex-col.justify-around.items-center.h-full
      template(v-if="drawer.from === '401'")
        p {{ $t('login.unauthorizedMsg') }}
        button.gold(style="min-width: unset; margin-bottom: 0", @click="handleDrawerBtnClick", data-at-element="login-confirm") {{ $t('common.confirm') }}
      template(v-else)
        p {{ $t('Shared.PromptMsg') }}.
        button.gold(style="min-width: unset; margin-bottom: 0", @click="handleDrawerBtnClick", data-at-element="pwa-install") {{ $t('Shared.Install') }}
  .safari-hint(v-if="isSafariHintShow")
    .clock(v-if="hintCountDown > 0") {{ hintCountDown }}
    .close(v-else @click="handleCloseSafariHint")
    p {{ $t('Shared.PromptMsg') }}.
    p 1. {{ $t('Shared.SafariHint0') }}
    p 2. {{ $t('Shared.SafariHint1') }}
      img.arrow-up(src="@/assets/images/common/arrowUp.svg")
    p 3. {{ $t('Shared.SafariHint2') }}
      img(src="@/assets/images/common/squarePlus.svg")
    button.normal(@click="isIosTutorialShow = true") {{ $t('Shared.Introduction') }}
  ios-tutorial(v-if="isIosTutorialShow" @close="handleTutorialClose")
  template(v-if="!isMaintain")
    div.loading-container.asset(v-if="!isInitDataLoaded || !isContentLoaded")
      .progress-container
        .progress-bar(:style="{ width: barWidth + '%' }")
  div.loading-container(v-if="$store.state.app.IsLoading")
    img.loading-img(:src="logoImgUrl", alt="logo")
  Rotate(v-show="isRotateShow && $store.state.isShowRotateModal")
  .close-customer-service(v-show="activeCustomerService" :class="activeCustomerService" @click="handleCloseCustomerService")
  //- video.youtube-player(
  //-   v-if="isSafariHintShow && isYoutubePlayerShow"
  //-   autoplay
  //-   muted
  //-   playsinline
  //-   controls
  //-   ref="IOSVideo"
  //-   @load="isYoutubePlayerLoaded = true"
  //- )
  //-   source(src="@/assets/video/iosPWA.mp4")
  //- img.youtube-player-close(v-if="isSafariHintShow && isYoutubePlayerShow && isYoutubePlayerLoaded" src="@/assets/images/modal/close.svg" @click="isYoutubePlayerShow = false")
</template>

<script>
import { mapGetters } from 'vuex';
import app from 'web-slot-module/mixins/app';
import { MessageBox } from 'element-ui';
import { handleScreenlock } from '~/utils';
import COMMON_CONSTANT from '~/utils/constant/common';
import IosTutorial from '@/components/IOStutorial';
import Mp3Player from '@/components/Mp3Player';
import Rotate from '@/views/Rotate';

export default {
  components: {
    Mp3Player,
    Rotate,
    IosTutorial,
  },
  mixins: [app],
  data() {
    return {
      isYoutubePlayerShow: false,
    };
  },
  computed: {
    ...mapGetters(['activeCustomerService']),
  },
  mounted() {
    this.handleRootClick();

    window.addEventListener('focus', this.handleRootClick);
  },
  methods: {
    handleRootClick() {
      // respond.io 開啟時，則在點擊畫面後，關閉客服視窗
      if (window.$respond?.is('chat:open')) {
        window.$respond.do('chat:close');
        this.$store.dispatch('actionSetActiveCustomerService', null);
      }
    },
    handleCloseCustomerService() {
      switch (this.activeCustomerService) {
        case COMMON_CONSTANT.customerService.RESPONDIO:
          if (window.$respond) {
            window.$respond.do('chat:close');
          }
          break;
      }
      this.$store.dispatch('actionSetActiveCustomerService', null);
    },
  },
};

</script>

<style lang='scss' scoped>
@import '@/assets/scss/app.scss';
</style>
