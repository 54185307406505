<template lang="pug">
.ios-tutorial()
  .img-box(:style="handleImgBoxStyle()")
    img.hint(v-for="i in 4" :src="require(`@/assets/images/ios-pwa/${i}-${imageLanguage}.webp`)")
  img.close(src="@/assets/images/modal/close.webp" @click="$emit('close')")
  .prev-btn(v-if="activeIdx > 1" @click="activeIdx = activeIdx - 1")
    i.el-icon-arrow-left
  .next-btn(v-if="activeIdx < 4" @click="activeIdx = activeIdx + 1")
    i.el-icon-arrow-right
  .description-box(:class="[`step-${activeIdx}`]")
    template(v-if="activeIdx === 1")
      p 1. {{ $t('Shared.SafariHint0') }}
      p 2. {{ $t('Shared.SafariHint1') }}
        img.arrow-up(src="@/assets/images/common/arrowUp.svg")
    template(v-if="activeIdx === 2")
      p {{ $t('Shared.SafariHint2') }}
        img.plus(src="@/assets/images/common/squarePlus.svg")
    p(v-if="activeIdx === 3") {{ $t('Shared.A2HS') }}
    p(v-if="activeIdx === 4") {{ $t('Shared.Finish')}} !
  .line-box
    span(v-for="i in 4" :class="{ active: i === activeIdx }" @click="activeIdx = i")
</template>

<script>
export default {
  inject: ['isRotate'],
  data() {
    return {
      activeIdx: 1,
    };
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
    imageLanguage() {
      switch (this.language) {
        case 'en':
          return 'en';
        default:
          return 'zh-hant';
      }
    },
  },
  methods: {
    handleImgBoxStyle() {
      const val = `${-100 * (this.activeIdx - 1)}%`;
      return {
        transform: `translateX(${val})`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_mixins';
.ios-tutorial {
  position: relative;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background:rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  .img-box {
    display: flex;
    flex-wrap: nowrap;
    transition: transform .5s;
    width: 100vw;
    img.hint {
      width: 100%;
      min-width: 100vw;
    }
  }
  .close {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 3%;
    right: 3%;
  }
  .prev-btn, .next-btn {
    width: 50px;
    height: 50px;
    background: rgba(103, 103, 105, 0.7);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    &.next-btn {
      right: 5%;
      left: unset;
    }
    i {
      color: #fff;
    }
  }
  .description-box {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    justify-content: center;
    p {
      height: 30px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      white-space: nowrap;
      img {
        height: 40px;
        position: relative;
        bottom: 5px;
        right: 10px;
        &.plus {
          bottom: 2px;
        }
      }
    }
    &.step-1 {
      width: 20%;
      margin: auto;
      align-items: flex-start;
    }
    &.step-4 {
      left: 75%;
      bottom: 25%;
    }
  }
  .line-box {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    span {
      width: 40px;
      height: 3px;
      background: gray;
      &.active {
        background: #fff;
      }
    }
  }
}
</style>
